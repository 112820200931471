var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    staticClass: "rounded cursor-pointer mb-0 border-0 p-1",
    attrs: {
      "id": "no-of-passenger",
      "variant": "flat-dark",
      "disabled": ""
    }
  }, [_c('span', {
    staticClass: "text-body float-left d-flex-center"
  }, [_c('img', {
    staticClass: "mr-50",
    attrs: {
      "src": require("@icons/users.svg")
    }
  }), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s("".concat(_vm.adt, " ").concat(_vm.$t('flight.Adult'), "\u30FB").concat(_vm.chd, " ").concat(_vm.$t('flight.Child'), "\u30FB").concat(_vm.inf, " ").concat(_vm.$t('flight.Infant'))))])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }