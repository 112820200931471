<template>
  <div>
    <b-button
      id="no-of-passenger"
      class="rounded cursor-pointer mb-0 border-0 p-1"
      variant="flat-dark"
      disabled
    >
      <span class="text-body float-left d-flex-center">
        <img
          src="@icons/users.svg"
          class="mr-50"
        >
        <span class="text-nowrap">
          {{
            `${adt} ${$t('flight.Adult')}・${chd} ${$t('flight.Child')}・${inf} ${$t('flight.Infant')}`
          }}</span>
      </span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import {
  toRefs,
} from '@vue/composition-api'

export default {
  components: {
    BButton,
  },
  props: {
    adult: {
      type: Number,
      default: 1,
    },
    child: {
      type: Number,
      default: 0,
    },
    infant: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { adult: adt, child: chd, infant: inf } = toRefs(props)

    return {
      adt,
      chd,
      inf,
    }
  },
}
</script>

<style lang="scss" scoped>
#popover {
  max-width: none;
}
</style>
